import React, {useEffect, useState} from "react";
import useLocalStorage from "./useLocalStorage";
import { translate, synonyms, translate2 } from './apis/translator-api';

import { FullScreen, useFullScreenHandle } from "react-full-screen";

function App() {
  const handle = useFullScreenHandle();
  const [showHeader, setShowHeader] = useState(true);
  const [globalText, setGlobalText] = useLocalStorage([], 'GLOBAL_BOOK_DATA');
  const [bookmark, setBookmark] = useLocalStorage(0, 'GLOBAL_BOOK_BOOKMARK');
  const [plainText, setPlainText] = useState('');

  const [width, setWidth] = useState(20);
  const [translatedText, setTranslatedText] = useState('');
  const [showTranslate, setShowTranslate] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ bottom: 0, left: 0 });

  useEffect(() => {
    if (globalText.length !== 0) {
      setPlainText(globalText[bookmark]);
    } else {
      setPlainText('');
    }
  }, [globalText, bookmark])

  function handleHeader() {
    setShowHeader(!showHeader);
  }

  function openBook(e) {
    const file = e.target.files[0];
    var reader = new FileReader();

    try {
      reader.readAsText(file, "UTF-8");

      reader.onload = function(evt) {
        let arrayInstance = evt.target.result.split('\r\n');
        arrayInstance = arrayInstance.filter(str => str !== '');
        setGlobalText(arrayInstance);
        setBookmark(0);
      }
    } catch {
      closeBook();
    }
  }

  function closeBook() {
    setGlobalText([]);
    setBookmark(0);
  }

  function nextPage() {
    if (globalText.length === 0) {
      return;
    }

    if (bookmark !== globalText.length - 1) {
      setBookmark(bookmark + 1);
    }

    setShowTranslate(false);
  }

  function backPage() {
    if (bookmark !== 0) {
      setBookmark(bookmark - 1);
    }

    setShowTranslate(false);
  }

  function wheelTurnPage(event) {
    if (event.deltaY > 0) {
      nextPage();
    } else {
      backPage();
    }
  }

  const handleSelectionEnd = () => {
    const selection = window.getSelection();
    if (selection && selection.toString()) {
      let newValue = selection.toString();

      translate(newValue)
        .then(res => {
          setTranslatedText(res);
          setShowTranslate(true);
        });
    }

    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      setMenuPosition({
        bottom: window.innerHeight - rect.top + 10,
        left: rect.left
      });
    }
  };

  const handleTranslate = () => {
    setShowTranslate(false);
  }

  useEffect(() => {
    const textArea = document.getElementById('textArea');
    const mainArea = document.getElementsByClassName('fullscreen');

    if (textArea) {
      textArea.addEventListener('mouseup', handleSelectionEnd);
      mainArea[0].addEventListener('mousedown', handleTranslate);
    }

    return () => {
      if (textArea) {
        textArea.removeEventListener('mouseup', handleSelectionEnd);
        mainArea[0].removeEventListener('mousedown', handleTranslate);
      }
    };
  }, []);

  return (
    <>
      <header style={{ top: `${showHeader ? -100 : 0}px` }}>
        <div className="header-item">
          <h2>Book</h2>
          <input type="file" id="openBookInput" accept=".txt" onChange={openBook} />
          <input type="button" onClick={closeBook} value={'Close book'} />
        </div>
        <div className="header-handler" onClick={handleHeader}></div>
        <div className="full-screen-handler" onClick={handle.enter}></div>
      </header>
      <FullScreen handle={handle} style={{ backgroundColor: '#202124' }}>
        <div id="textArea" onWheel={wheelTurnPage} onContextMenu={e => e.preventDefault()}>
          {plainText}
        </div>
        <div className="page-navigator">
          <div className="back-page" onClick={backPage} on></div>
          <div>
            {globalText.length}/
            <input className="input-number" type='number' min={1} max={globalText.length} value={bookmark + 1} onChange={e => setBookmark(e.target.value - 1)} placeholder={globalText.length} />
          </div>
          <div className="next-page" onClick={nextPage}></div>
        </div>
        {
          showTranslate &&
          (<div className="translate-menu" style={{ ...menuPosition }}>
            {translatedText?.translate}
          </div>)
        }
      </FullScreen>
    </>
  );
}

export default App;
