const API = 'https://beige-badger-gear.cyclic.app';
const HOMEAPI = 'http://localhost:5000';
const API2 = 'http://185.65.244.65:5000';
const KEY = '12345678';

async function translate(str) {
  return await doRequest('translate', str);
}

async function synonyms(str) {
  return await doRequest('v1s', str);
}

async function translate2(str) {
  return await doRequest('v2', str);
}

async function doRequest(version, str) {
  try {
    const response = await fetch(`${API2}/${version}/${KEY}/${encodeURIComponent(str)}`);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error:', error);
  }

  return null;
}

export { translate, synonyms, translate2};