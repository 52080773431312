import {useEffect, useState} from 'react'

function useLocalStorage(initialState, stringKey) {
  const [data, setData] = useState(initialState);

  useEffect(() => {
    const blob = localStorage.getItem(stringKey);

    if (blob != null) {
      setData(JSON.parse(blob));
    }
  }, []);

  useEffect(() => {
    if (data != null) {
      localStorage.setItem(stringKey, JSON.stringify(data));
    }
  }, [data]);

  return [data, setData];
}

export default useLocalStorage;
